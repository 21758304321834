
import QuotationRequestFullDetails from "@/models/QuotationRequestFullDetails";
import { Vue, Component, Prop } from "vue-property-decorator";
import InputField from "@/components/InputField.vue";
import { IFile } from "@/models/File";

@Component({
  components: {
    InputField
  }
})
export default class QuotationRequestModal extends Vue {
  @Prop({
    default: () => ({
      name: "",
      mobilePhone: "",
      email: "",
      timeLimit: "",
      zipCode: "",
      category: "",
      otherInformation: "",
      questionsAndAnswers: [],
      files: []
    })
  })
  quotationRequest!: QuotationRequestFullDetails;

  downloadFile(file: IFile): void {
    this.$emit("download-file", file);
  }

  close() {
    this.$emit("close");
  }
}
