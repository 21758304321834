import { IQuestionAndAnswerSlim } from "./QuestionAndAnswerSlim";
import { IFile } from "./File";

export interface IQuotationRequestFullDetails {
  id: number;
  name: string;
  mobilePhone: string;
  email: string;
  timeLimit: string;
  zipCode: string;
  category: string;
  otherInformation: string;
  questionsAndAnswers: Array<IQuestionAndAnswerSlim>;
  files: Array<IFile>;
  hidden: boolean;
}

export class QuotationRequestFullDetailsDto
  implements IQuotationRequestFullDetails {
  id: number = 0;
  name: string = "";
  mobilePhone: string = "";
  email: string = "";
  timeLimit: string = "";
  zipCode: string = "";
  category: string = "";
  otherInformation: string = "";
  questionsAndAnswers: Array<IQuestionAndAnswerSlim> = [];
  files: Array<IFile> = [];
  hidden = true;
}

export default class QuotationRequestFullDetails extends QuotationRequestFullDetailsDto {
  constructor(dto: QuotationRequestFullDetailsDto) {
    super();
    Object.assign(this, dto);
  }
}
