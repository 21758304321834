
import { Vue, Component, Prop } from "vue-property-decorator";
import QuotationRequestSlim, {
  QuotationRequestSlimDto
} from "@/models/QuotationRequestSlim";
import QuotationRequestFullDetails from "@/models/QuotationRequestFullDetails";
import { UserService } from "@/api/UserServiceApi";
import { FileService } from "@/api/FileServiceApi";
import { IFile } from "@/models/File";
import { saveAs } from "file-saver";
import QuotationRequestModal from "@/components/QuotationRequestModal.vue";

@Component({
  components: { QuotationRequestModal, Modal: QuotationRequestModal }
})
export default class DashboardQuotationRequests extends Vue {
  slimQuotationRequests: Array<QuotationRequestSlim> = [];

  details: QuotationRequestFullDetails[] | null[] = [];
  detailsAreLoading: Array<boolean> = [];

  limit: number = 10;
  offset: number = 0;
  currentPage: number = 1;
  pageSize: number = 0;

  async created() {
    await UserService.GetQuotationRequests()
      .then(async response => {
        this.slimQuotationRequests = response.data.map(
          (quotationRequest: QuotationRequestSlimDto) =>
            new QuotationRequestSlim(quotationRequest)
        );
      })
      .catch(error => {});
  }

  get pagesCount(): number {
    return Math.ceil(this.slimQuotationRequests.length / this.limit);
  }

  get limitDisplay(): number {
    const quotationRequestCount = this.slimQuotationRequests.length;
    if (this.limit > quotationRequestCount) {
      return quotationRequestCount;
    }
    return this.limit;
  }

  paginateNext() {
    this.resetState();
    this.currentPage++;
    this.offset += this.limit;
  }

  paginatePrevious() {
    this.resetState();
    this.currentPage--;
    this.offset -= this.limit;
  }

  resetState() {
    this.detailsAreLoading = [];
    this.details = [];
  }

  get quotationRequests(): Array<QuotationRequestSlim> {
    const offset = this.offset;
    const pageSize = this.pageSize;
    const page = this.currentPage;
    const size = page * pageSize;
    this.pageSize = this.limit;
    return this.slimQuotationRequests.slice(offset, size);
  }

  showingDetailedInformation(index: number): boolean {
    return this.details[index] != null && this.details[index]!.hidden == false;
  }

  async loadDetailedInformation(index: number, quotationRequestId: number) {
    if (this.details[index] == null) {
      Vue.set(this.detailsAreLoading, index, true);
      await UserService.GetQuotationRequest(quotationRequestId)
        .then(async response => {
          const quotationRequestDetails = new QuotationRequestFullDetails(
            response.data
          );
          quotationRequestDetails.hidden = false;
          Vue.set(this.details, index, quotationRequestDetails);
          Vue.set(this.detailsAreLoading, index, false);
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    } else {
      this.details[index]!.hidden = !this.details[index]!.hidden;
    }
  }

  isLoading(index: number): boolean {
    return this.detailsAreLoading[index];
  }

  async downloadFile(file: IFile) {
    await FileService.DownloadFile(file.id)
      .then(async response => {
        var blob = new Blob([response.data]);
        saveAs(blob, file.fileName);
      })
      .catch(error => {});
  }
}
