export interface IQuotationRequestSlim {
  id: number;
  created: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  timeLimit: string;
  category: string;
}

export class QuotationRequestSlimDto implements IQuotationRequestSlim {
  id: number = 0;
  created: string = "";
  firstName: string = "";
  lastName: string = "";
  mobilePhone: string = "";
  email: string = "";
  timeLimit: string = "";
  category: string = "";
}

export default class QuotationRequestSlim extends QuotationRequestSlimDto {
  constructor(dto: QuotationRequestSlimDto) {
    super();
    Object.assign(this, dto);
  }
}
